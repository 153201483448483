import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Emojis from '../components/emojis';
import Layout from '../components/layout';
import Image from '../components/image';
import Meta from '../components/meta';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <Meta title="Consider all users" keywords={['gatsby', 'application', 'react']} />
      <div className='relative text-center overflow-hidden w-full min-h-screen flex flex-col items-center justify-center'>
  <div className='relative z-20 px-4 md:px-0'>
    <div className='bg-white  py-8 px-12'>
      <h1 className='font-semibold tracking-wide text-black text-2xl'> unbiased.design</h1>
      <p className='font-normal italic tracking-wide text-black text-lg mt-3'>Because all users should be considered.</p>
    </div>
    <div className='mt-10 w-full'>
      <div className='bg-white px-6 py-2 w-auto inline-block flex justify-between'>
        <a className='font-semibold text-lg cursor-pointer hover:text-orange-500'>Types</a>
        <a className='font-semibold text-lg cursor-pointer hover:text-orange-500'>Techniques</a>
        <a className='font-semibold text-lg cursor-pointer hover:text-orange-500'>Templates</a>
      </div>
    </div>
    <div className='mt-10'>
      <input type="email" placeholder="Subscribe" className='py-2 px-4 w-full' />
    </div>
  </div>
  <div className="absolute inset-0 opacity-50 text-center text-3xl w-full flex flex-col items-center z-10">
    <Emojis />
  </div>
      </div>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    